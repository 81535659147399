import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDebounce } from 'ahooks';
import {
  loggedInAPI,
  buildAPIURL,
  buildSubscriptionAPIURL,
  subscriptionLoggedInAPI,
  useEnvState,
  envStore,
} from 'stores/AuthStore';
import { toast } from 'components';
import { convertObjToString } from 'utils';

const getLogs = async distinct_id => {
  if (distinct_id === '') {
    return null;
  }
  let url = buildAPIURL(`/subscriber/${distinct_id}/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useSubscriberAPI({ distinct_id = '', enabled = true }) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/subscriber`, distinct_id],
    () => getLogs(distinct_id),
    { retry: false, enabled: enabled }
  );
}

function useActivateIdentity(distinctID, identityID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(
    `/subscriber/${distinctID}/identity/${identityID}/activate`
  );

  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(`${envState.currentEnv.get()}/subscriber`);
    },
    onError: error => {
      toast(`Error while activating: ${error.response.data.message}`, 'error');
      throw new Error(error.response.data.message);
    },
  });
}

function useUpdateSubscriber({ distId, delayTime = 0 }) {
  const envState = useEnvState();
  const queryClient = useQueryClient();

  return useMutation(
    data => {
      return loggedInAPI.post(
        `v1/${envState.currentEnv.get()}/subscriber/${distId}/identity_events/`,
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          `${envState.currentEnv.get()}/subscriber`
        );
        queryClient.invalidateQueries(
          `${envState.currentEnv.get()}/subscriber/all_preferences`
        );
        setTimeout(() => {
          queryClient.invalidateQueries(
            `${envState.currentEnv.get()}/subscriber`
          );
        }, delayTime);
      },
    },
    {
      onError: error => {
        toast(error.response.data.message, 'error');
        throw new Error(error.response.data.message);
      },
    }
  );
}

const getSubscribersList = async ({ apiQueryString }) => {
  let url = `/v2/${envStore.currentEnv.get()}/subscriber/?${apiQueryString}`;
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useSubscribersListAPI({ queryString }) {
  const envState = useEnvState();
  const queryMap = new URLSearchParams(queryString);
  const queryEntries = queryMap.entries();
  const newQs = new URLSearchParams();
  const page = queryMap.get('page') || 0;
  const userChannel = queryMap.get('user_channel');
  const userChannelCount = queryMap.get('user_channel_count');
  const multipleSupport = [
    'distinct_id',
    'email',
    'phone_number',
    'active_channel',
    'language',
    'timezone',
  ];

  for (let item of queryEntries) {
    if (multipleSupport.includes(item[0])) {
      newQs.append(`${item[0]}[]`, item[1]);
    } else {
      newQs.append(`${item[0]}`, item[1]);
    }
  }

  if (userChannel && !userChannelCount) {
    newQs.delete('user_channel');
  }
  if (userChannelCount && !userChannel) {
    newQs.delete('user_channel_count');
  }

  newQs.append('limit', 20);
  newQs.append('offset', page * 20);

  const apiQueryString = newQs.toString();

  return useQuery(
    [`${envState.currentEnv.get()}/subscriber_list`, apiQueryString],
    () => getSubscribersList({ apiQueryString }),
    { keepPreviousData: true }
  );
}

const getLists = async ({ page = 0, debouncedSearchText, limit, listType }) => {
  const params = {
    limit: limit || 20,
    offset: page * 20,
    q: debouncedSearchText,
    list_type: listType,
  };

  let url = buildAPIURL(
    `/client_subscriber_list/${convertObjToString(params)}`
  );
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useLists({ page = 0, searchText, limit, listType }) {
  const envState = useEnvState();
  const debouncedSearchText = useDebounce(searchText, 500);

  return useQuery(
    [
      `${envState.currentEnv.get()}/client_subscriber_list`,
      page,
      debouncedSearchText,
      limit,
      listType,
    ],
    () => getLists({ page, debouncedSearchText, limit, listType }),
    { keepPreviousData: true }
  );
}

const getSubscriberListDetails = async listID => {
  let url = buildAPIURL(`/client_subscriber_list/${listID}/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useSubscriberListDetails(listID, enabled = true) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/subscriber_list_Details`, listID],
    () => getSubscriberListDetails(listID),
    {
      enabled: enabled,
      retry: false,
    }
  );
}

function useCreateBroadcast(listID) {
  const url = buildAPIURL(`/client_subscriber_list/${listID}/broadcast/`);

  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast(
        `Error while triggering broadcast: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

function useCreateList() {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL('/client_subscriber_list/');

  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/client_subscriber_list`
      );
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/subscriber_list_Details`
      );
    },
    onError: error => {
      toast(
        `Error while creating user list: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

const getPresignedURL = async () => {
  let url = buildAPIURL(`/upload_file/presigned_url/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useUpdateUsersInList(listID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();

  const url = buildAPIURL(
    `/client_subscriber_list/${listID}/subscriber/upload_from_csv/`
  );

  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/task_request`
      );
    },
    onError: error => {
      toast(
        `Error while updating user list: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

const getTaskList = async ({ page = 0, listID, taskType, failureLogs }) => {
  const params = {
    limit: 20,
    offset: page * 20,
    list_id: listID,
    task_type: taskType
      ? taskType
      : 'subscriber_list_csv_upload,database_subscriber_list_sync',
    status: failureLogs ? 'failed' : '',
  };

  let url = buildAPIURL(`/task_request/${convertObjToString(params)}`);

  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTaskList({ page = 0, isProgress, listID, taskType, failureLogs }) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/task_request`,
      page,
      isProgress,
      listID,
      taskType,
      failureLogs,
    ],
    () => getTaskList({ page, listID, taskType, failureLogs }),
    {
      keepPreviousData: true,
      refetchInterval: isProgress ? 15000 : false,
    }
  );
}

const getTaskRequestDetails = async taskID => {
  let url = buildAPIURL(`/task_request/${taskID}/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTaskRequestDetails(taskID, enabled) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/task_request_details`, taskID],
    () => getTaskRequestDetails(taskID),
    {
      keepPreviousData: true,
      refetchInterval: 8000,
      enabled: enabled,
    }
  );
}

const getSyncTaskList = async ({ searchText, matchType }) => {
  const params = {
    search: searchText,
    match_type: matchType,
  };

  let url = buildAPIURL(`/subscriber_sync_task/${convertObjToString(params)}`);

  const { data } = await loggedInAPI.get(url);
  return data;
};

function useSyncTaskList({ searchText, matchType }) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/subscriber_sync_task/`,
      searchText,
      matchType,
    ],
    () => getSyncTaskList({ searchText, matchType })
  );
}

function useCreateTask() {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL('/subscriber_sync_task/');

  return useMutation(data => loggedInAPI.post(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/subscriber_sync_task/`
      );
    },
    onError: error => {
      toast(
        `Error while creating task: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

const getTaskDetails = async taskID => {
  let url = buildAPIURL(`/subscriber_sync_task/${taskID}`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTaskDetails(taskID) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/subscriber_sync_task_detail`, taskID],
    () => getTaskDetails(taskID)
  );
}

const getTaskVersionList = async taskID => {
  let url = buildAPIURL(`/subscriber_sync_task/${taskID}/version/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTaskVersionList(taskID) {
  const envState = useEnvState();
  return useQuery(
    [`${envState.currentEnv.get()}/subscriber_sync_task/version_list`, taskID],
    () => getTaskVersionList(taskID)
  );
}

const getTaskVersionDetails = async taskID => {
  let url = buildAPIURL(`/subscriber_sync_task/${taskID}/version/_/`);
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useTaskVersionDetails(taskID) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/subscriber_sync_task/version_detail`,
      taskID,
    ],
    () => getTaskVersionDetails(taskID)
  );
}

function useDryRun(taskID, versionID) {
  const url = buildAPIURL(
    `/subscriber_sync_task/${taskID}/version/${versionID}/dry_run/`
  );

  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast(
        `Error while running query: ${
          error.response.data.message || error.response.data.detail
        }`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

function useDryRunCount(taskID, versionID) {
  const url = buildAPIURL(
    `/subscriber_sync_task/${taskID}/version/${versionID}/dry_run/count/`
  );

  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast(
        `Error while fetching dryrun count: ${
          error.response.data.message || error.response.data.detail
        }`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

function useSaveQuery(taskID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/subscriber_sync_task/${taskID}/version/_/`);

  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/subscriber_sync_task/version_detail`
      );
    },
    onError: error => {
      toast(
        `Error while saving query: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

function useEditTaskDetails(taskID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildAPIURL(`/subscriber_sync_task/${taskID}/`);

  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/subscriber_sync_task_detail`
      );
    },
    onError: error => {
      toast(
        `Error while saving details: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

const getAllPreferenceList = async (distinctID, tenantID) => {
  let url = buildSubscriptionAPIURL(
    `/subscriber/${encodeURIComponent(
      distinctID
    )}/preference/?tenant_id=${encodeURIComponent(tenantID)}`
  );

  const { data } = await subscriptionLoggedInAPI.get(url);
  return data;
};

function useAllPreferenceList(distinctID, tenantID) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/subscriber/all_preferences`,
      distinctID,
      tenantID,
    ],
    () => getAllPreferenceList(distinctID, tenantID)
  );
}

function useUpdateChannelPreferences(distinctID) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildSubscriptionAPIURL(
    `/subscriber/${encodeURIComponent(
      distinctID
    )}/preference/channel_preference/`
  );

  return useMutation(data => subscriptionLoggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/subscriber/all_preferences`
      );
    },
    onError: error => {
      toast(
        `Error while updating channel preferences: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

function useUpdateCategoryPreferences({ distinctID, category, tenantID }) {
  const queryClient = useQueryClient();
  const envState = useEnvState();
  const url = buildSubscriptionAPIURL(
    `/subscriber/${encodeURIComponent(
      distinctID
    )}/preference/category/${category}/?tenant_id=${encodeURIComponent(
      tenantID
    )}`
  );

  return useMutation(data => loggedInAPI.patch(url, data), {
    onSettled: () => {
      queryClient.invalidateQueries(
        `${envState.currentEnv.get()}/subscriber/all_preferences`
      );
    },
    onError: error => {
      toast(
        `Error while updating category preferences: ${error.response.data.message}`,
        'error'
      );
      throw new Error(error.response.data.message);
    },
  });
}

function useSyncNow(taskID) {
  const url = buildAPIURL(`/subscriber_sync_task/${taskID}/schedule_now/`);

  return useMutation(data => loggedInAPI.post(url, data), {
    onError: error => {
      toast(`Error while syncing now: ${error.response.data.message}`, 'error');
      throw new Error(error.response.data.message);
    },
  });
}

const getListUsers = async ({ page, listID }) => {
  const params = {
    limit: 20,
    before: page?.before,
    after: page?.after,
  };
  let url = buildAPIURL(
    `/subscriber_list/${listID}/subscriber/${convertObjToString(params)}`
  );
  const { data } = await loggedInAPI.get(url);
  return data;
};

function useListUsers({ listID, page }) {
  const envState = useEnvState();
  return useQuery(
    [
      `${envState.currentEnv.get()}/client_subscriber_list/subscriber`,
      page,
      listID,
    ],
    () => getListUsers({ page, listID })
  );
}

export {
  useSubscriberAPI,
  useSubscribersListAPI,
  useSubscriberListDetails,
  useLists,
  useCreateBroadcast,
  useCreateList,
  getPresignedURL,
  useUpdateUsersInList,
  useTaskList,
  useTaskRequestDetails,
  useSyncTaskList,
  useTaskDetails,
  useCreateTask,
  useTaskVersionList,
  useDryRun,
  useDryRunCount,
  useTaskVersionDetails,
  useSaveQuery,
  useEditTaskDetails,
  getTaskList,
  useUpdateSubscriber,
  useActivateIdentity,
  useAllPreferenceList,
  useUpdateChannelPreferences,
  useUpdateCategoryPreferences,
  useSyncNow,
  useListUsers,
};
